/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconCloseDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M2.418 2.918a1.43 1.43 0 0 1 2.02 0L12 10.48l7.561-7.562a1.429 1.429 0 0 1 2.02 2.02l-7.56 7.562 7.56 7.561a1.429 1.429 0 1 1-2.02 2.02L12 14.522l-7.561 7.56a1.429 1.429 0 1 1-2.02-2.02l7.56-7.561-7.56-7.561a1.43 1.43 0 0 1 0-2.02'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconCloseDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconCloseDefault
