import { isUndefined } from 'lodash'

export const ASPECT_RATIOS = [
  'auto',
  '1x1',
  '2x3',
  '3x4',
  '4x3',
  '9x16',
  '12x5',
  '16x9',
  '21x9',
] as const

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export type AspectRatio = ArrayElement<typeof ASPECT_RATIOS>

export const parseInputErrors = <T = Error>(error?: T | T[]): T | undefined => {
  if (!error) {
    return undefined
  }
  if (Array.isArray(error)) {
    return error[0]
  }
  return error
}

export const NumberToPX = (value: number | string) =>
  typeof value === 'number' ? `${value}px` : value

declare global {
  interface Document {
    mozCancelFullScreen?: () => void
    webkitExitFullscreen?: () => void
    msExitFullscreen?: () => void
  }
}

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen()
  }
}

export const FormatClassTitle = (title = '', instructorName = '') => {
  if (title.toLowerCase().indexOf(instructorName.toLowerCase()) === -1) {
    return title
  }
  return title.trim().slice(instructorName.length).trim()
}

const undefinedFallback = <T>(
  ...args: (T | undefined)[]
): T | number | undefined => {
  const [value, ...others] = args

  if (isUndefined(value)) {
    if (others.length === 0) {
      return 0
    }

    return undefinedFallback(...others)
  }

  return value
}

interface Responsive {
  gteXL?: boolean
  gteLG?: boolean
  gteMD?: boolean
  gteSM?: boolean
}

export const responsiveValues = <T>(
  { gteXL, gteLG, gteMD, gteSM }: Responsive,
  xl?: T,
  lg?: T,
  md?: T,
  sm?: T,
  xs?: T,
): T | number => {
  if (gteXL) {
    return undefinedFallback(xl, lg, md, sm, xs) ?? 0
  }

  if (gteLG) {
    return undefinedFallback(lg, md, sm, xs) ?? 0
  }

  if (gteMD) {
    return undefinedFallback(md, sm, xs) ?? 0
  }

  if (gteSM) {
    return undefinedFallback(sm, xs) ?? 0
  }

  return xs ?? 0
}

declare global {
  interface Element {
    matchesSelector?: (s: string) => boolean
    mozMatchesSelector?: (s: string) => boolean
    msMatchesSelector?: (s: string) => boolean
    oMatchesSelector?: (s: string) => boolean
  }
}

export const getClosest = (
  elem: Element | null | undefined,
  selector: string,
): Element | null => {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector
  }

  if (!elem) {
    return null
  }

  let check: Element | ParentNode | null = elem

  // Get closest match
  for (; check && check !== document; check = check.parentNode) {
    if ((check as Element).matches?.(selector)) return check as Element
  }

  return null
}
