import React from 'react'
import { withI18nContext, useTranslation } from '@mc/i18n'

import i18nInstance from '../../i18n/i18nInstance'
import Button, { ButtonProps } from '../Button'
import { IconCloseDefault } from '../Icons'

const DropdownClose = (props: ButtonProps) => {
  const { t } = useTranslation('@mc/design-system')

  return (
    <Button kind='link' symmetrical {...props}>
      <IconCloseDefault
        className='mc-dropdown__close-icon'
        srText={t('dropdown.closeButtonAlt')}
      />
    </Button>
  )
}

export default withI18nContext(i18nInstance)(DropdownClose)
