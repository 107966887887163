import React, { Children, cloneElement } from 'react'
import { isArray, isEmpty, isFunction, isObject } from 'lodash'

interface ChildProps {
  className?: string
  onClick?: (event: MouseEvent) => void
}

const isEmptyObject = (obj: unknown): obj is Record<string, never> =>
  isEmpty(obj) && !isFunction(obj) && isObject(obj)

export const isNotCloneable = (
  obj: unknown,
): obj is
  | undefined
  | null
  | string
  | number
  | boolean
  | React.ReactNode[]
  | Record<string, never> =>
  !obj ||
  typeof obj === 'string' ||
  typeof obj === 'number' ||
  typeof obj === 'boolean' ||
  isEmptyObject(obj)

export const renderChildren = <TProps = Record<string, unknown>>(
  children: React.ReactNode | ((p: TProps) => React.ReactNode),
  props: TProps & ChildProps,
): React.ReactElement | React.ReactElement[] | React.ReactNode => {
  if (isFunction(children)) {
    return children(props)
  }

  if (isNotCloneable(children)) {
    return children
  }

  return Children.map(children, (child) => {
    if (isNotCloneable(child)) {
      return child
    }

    if (isArray(child)) {
      return Children.map(child, renderChildren)
    }

    const ch = child as React.ReactElement

    return cloneElement(ch, {
      ...ch.props,
      ...props,
      className: `${ch.props.className || ''} ${props.className || ''}`,
      onClick:
        ch.props.onClick || props.onClick
          ? (event: MouseEvent) => {
              if (ch.props.onClick) {
                ch.props.onClick(event)
              }
              if (props.onClick) {
                props.onClick(event)
              }
            }
          : undefined,
    })
  })
}
