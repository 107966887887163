import i18n, { TFunction } from 'i18next'
import React, { forwardRef } from 'react'
import {
  I18nextProvider,
  withTranslation,
  WithTranslationProps,
} from 'react-i18next'

interface I18nContextOptions {
  ns?: string
}

export interface ComponentProxyProps {
  ref?: React.Ref<unknown>
  i18n?: typeof i18n
  t?: TFunction
}

export const withI18nContext =
  (i18nInstance: typeof i18n, { ns }: I18nContextOptions = {}) =>
  <TInnerProps, TOuterProps = Omit<TInnerProps, keyof ComponentProxyProps>>(
    Component: React.ComponentType<TInnerProps>,
  ): React.ComponentType<TOuterProps> => {
    const isClass =
      (Component as React.ExoticComponent).$$typeof !==
        Symbol.for('react.forward_ref') &&
      !!Component.prototype?.isReactComponent

    const WithTranslationProxy = isClass
      ? withTranslation(ns)(
          Component as React.ComponentClass<TInnerProps & WithTranslationProps>,
        )
      : Component

    const WrappedComponent = forwardRef<unknown, TOuterProps>((props, ref) => (
      <I18nextProvider i18n={i18nInstance}>
        <WithTranslationProxy
          {...(props as unknown as TInnerProps)}
          {...(isClass ? { i18n: i18nInstance } : {})}
          ref={ref}
        />
      </I18nextProvider>
    )) as React.ComponentType<React.PropsWithoutRef<TOuterProps>>

    WrappedComponent.displayName = 'WrappedI18nComponent'

    return WrappedComponent as React.ComponentType<TOuterProps>
  }
