import React, { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from 'reakit/Button'
import { As } from 'reakit-utils'

import Loader from '../Icon/Loader'

export const ButtonKinds = {
  APPLE: 'apple',
  CONTROL: 'control',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  LINK: 'link',
  LINKED_IN: 'linked-in',
  PLAY: 'play',
  MESSENGER: 'messenger',
  PAYPAL: 'paypal',
  PINTEREST: 'pinterest',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  TWITTER: 'twitter',
  UTILITY: 'utility',
} as const

export type ButtonKindValue = (typeof ButtonKinds)[keyof typeof ButtonKinds]

export const ButtonSizes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  // deprecated
  SMALL: 'small',
  MEDIUM: 'medium',
} as const

export type ButtonSizeValue = (typeof ButtonSizes)[keyof typeof ButtonSizes]

const getTranslatedSize = (size: ButtonSizeValue) => {
  if (!Object.values(ButtonSizes).includes(size)) {
    return ButtonSizes.MD
  }

  switch (size) {
    case ButtonSizes.SMALL:
      return ButtonSizes.SM
    case ButtonSizes.MEDIUM:
      return ButtonSizes.MD
    default:
      return size
  }
}

export interface ButtonProps extends BaseButtonProps {
  as?: As
  children?: React.ReactNode | React.ReactNode[] | string
  className?: string
  css?: Record<string, string>
  fullWidth?: boolean
  href?: string | { pathname: string; query?: Record<string, string> }
  kind?: ButtonKindValue
  loading?: boolean
  onClick?: React.MouseEventHandler<Element>
  rounded?: boolean
  size?: ButtonSizeValue
  symmetrical?: boolean
  // For any additional props that go with the value of "as"
  [k: string]: unknown
}

interface InnerProps extends ButtonProps {
  innerRef: React.Ref<HTMLButtonElement> | null
}

class _Button extends PureComponent<InnerProps> {
  static propTypes = {
    as: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
      PropTypes.func,
      PropTypes.elementType,
    ]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    kind: PropTypes.oneOf([...new Set(Object.values(ButtonKinds))]),
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    rounded: PropTypes.bool,
    size: PropTypes.oneOf([...new Set(Object.values(ButtonSizes))]),
    symmetrical: PropTypes.bool,
  }

  static defaultProps = {
    as: 'button',
    kind: ButtonKinds.PRIMARY,
    size: ButtonSizes.MD,
    onClick: () => {},
  }

  element: React.Ref<HTMLButtonElement> | React.RefObject<HTMLButtonElement>

  constructor(props: InnerProps) {
    super(props)
    this.element = this.props.innerRef || React.createRef<HTMLButtonElement>()
  }

  render() {
    const {
      as,
      children,
      className,
      fullWidth,
      kind,
      loading,
      rounded,
      size,
      symmetrical,
      innerRef,
      isBasic,
      ...props
    } = this.props

    const translatedSize = getTranslatedSize(size ?? ButtonSizes.MD)
    const classNames = cn({
      'c-button': true,
      'c-button--full-width': fullWidth,
      'c-button--loading': loading,
      'c-button--symmetrical': symmetrical,
      'c-button--symmetrical mc-corners--circle': rounded,
      [`c-button--${kind}`]: kind,
      [`c-button--${translatedSize}`]: translatedSize,
      [className ?? '']: className,
    })

    return (
      <BaseButton
        as={as ?? 'button'}
        className={classNames}
        ref={this.element}
        {...(typeof as !== 'string' ? { isBasic } : {})}
        {...props}
      >
        {!loading && children}
        {loading && (
          <>
            <span className='c-button__content'>{children}</span>
            <Loader className='c-button__loader' />
          </>
        )}
      </BaseButton>
    )
  }
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <_Button innerRef={ref} {...props} />,
)

Button.displayName = 'Button'

export default Button
