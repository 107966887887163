import React, { useId, useState } from 'react'
import PropTypes from 'prop-types'
import { Placement } from '@popperjs/core'

import { PROP_TYPE_CHILDREN } from '../constants'

interface DropdownContext {
  toggleElement: HTMLElement | null
  setToggleElement: (el: HTMLElement | null) => void
  dropdownElement: HTMLElement | null
  setDropdownElement: (el: HTMLElement | null) => void

  toggle: () => void

  ariaControls?: string
  autoFlip?: boolean
  placement?: Placement
  fixed?: boolean
  show?: boolean
  focusTrap?: boolean
}

interface DropdownProps {
  children: React.ReactElement | React.ReactElement[]
  ariaControls?: string
  autoFlip?: boolean
  fixed?: boolean
  placement?: Placement
  focusTrap?: boolean
}

export const DropdownContext = React.createContext<DropdownContext>({
  toggle: () => {},
  toggleElement: null,
  setToggleElement: () => {},
  setDropdownElement: () => {},
  dropdownElement: null,
})
export const { Provider, Consumer } = DropdownContext

const Dropdown = ({
  ariaControls,
  autoFlip = true,
  children,
  fixed = false,
  placement = 'bottom-start',
  focusTrap = true,
}: DropdownProps) => {
  const [show, setShow] = useState(false)
  const [toggleElement, setToggleElement] = useState<HTMLElement | null>(null)
  const [dropdownElement, setDropdownElement] = useState<HTMLElement | null>(
    null,
  )
  const id = useId()
  const ariaControlsProp = `${ariaControls || 'dropdown'}-${id}`

  const toggle = () => {
    setShow((prevShow) => !prevShow)
  }

  return (
    <Provider
      value={{
        toggleElement,
        setToggleElement,
        dropdownElement,
        setDropdownElement,

        toggle,

        ariaControls: ariaControlsProp,
        autoFlip,
        placement,
        fixed,
        show,
        focusTrap,
      }}
    >
      {children}
    </Provider>
  )
}

Dropdown.propTypes = {
  ariaControls: PropTypes.string,
  autoFlip: PropTypes.bool,
  children: PROP_TYPE_CHILDREN,
  fixed: PropTypes.bool,
  placement: PropTypes.string,
  focusTrap: PropTypes.bool,
}

export default Dropdown
