import { useMemo } from 'react'
import humps from 'humps'
import { isUndefined, omitBy } from 'lodash'

import { usePersonaTrack } from './tracking'

const filterUndefinedProps = (
  obj: Record<string, string | boolean | number | undefined>,
) => omitBy(obj, isUndefined)

interface CookingCaseProps {
  contentPageTab?: 'library' | 'history' | null
  contentCategoryTab?: string | null
  contentUuid?: string | null
  contentTitle?: string | null
  recommendationId?: string | null
  currentPage?: string
}

export interface PersonaSignClickProps {
  instructorSlug?: string
  location?: string
  cta?: string
  action?: string
  additionalAnalytics?: Record<string, string | number | boolean | undefined>
  rowPositionIndex?: string
  tilePositionIndex?: string | number
  subrowPositionIndex?: string | number
  level?: string
  page?: string
  pageSource?: string
  isUpcomingBeta?: boolean
  tileLink?: string
  tileTitle?: string
  tileSubtitle?: string
}

export interface PersonaViewedProps {
  location?: string
  instructorSlug?: string
  conversationSource?: string
  suggestedPrompt?: string
  callId?: string
  page?: string
}

export interface PersonaEmailCapturedProps extends PersonaSignClickProps {
  email?: string
}

export interface PersonaClickProps
  extends PersonaSignClickProps,
    CookingCaseProps {
  suggestedPrompt?: string
  callId?: string
  conversationSource?: string
}

interface PersonaConversationProps extends CookingCaseProps {
  instructorSlug: string
  isUser?: boolean
  messageId?: string
  callId?: string
  suggestedPrompt?: string
  responseSource: string
}

interface PersonaNegativeFeedbackProps {
  instructorSlug?: string
  issues?: string[]
  additionalFeedback?: string
  callId?: string
  conversationSource?: string
  suggestedPrompt?: string
}

export interface PersonaRowViewedProps {
  rowName?: string
  rowPositionIndex: number | string
  page?: string
  isUpcomingBeta?: boolean
}

export interface PersonaRowTileViewedProps extends PersonaRowViewedProps {
  subrowPositionIndex?: number | string
  tilePositionIndex: number | string
  tileTitle?: string
  tileSubtitle?: string
  tileLink?: string
  tileInstructorSlug?: string
  tileClickType?: string
}

export interface PersonaRowScrolledProps extends PersonaRowViewedProps {
  direction: 'left' | 'right'
  autoScroll?: boolean
}

const filterCookingCaseProps = ({
  contentPageTab,
  contentCategoryTab,
  contentTitle,
  contentUuid,
  currentPage,
  recommendationId,
}: CookingCaseProps) => ({
  ...(contentPageTab &&
    !contentUuid &&
    !contentTitle && { content_page_tab: contentPageTab }),
  ...(contentPageTab === 'library' &&
    !contentUuid &&
    !contentTitle && { content_category_tab: contentCategoryTab }),
  ...(contentUuid && { content_uuid: contentUuid }),
  ...(contentTitle && { content_title: contentTitle }),
  ...(currentPage && { page: currentPage }),
  // TODO: Add recommendation_id to the event
  recommendation_id: recommendationId,
})

export const usePersonaEvents = () => {
  const { track, trackPage, isLoggedIn, loaded } = usePersonaTrack()

  return useMemo(() => {
    // PRE PW EVENTS
    const trackPersonaLandingViewed = ({
      isUpcomingBeta,
    }: {
      isUpcomingBeta?: boolean
    }) => {
      if (loaded) {
        trackPage('Page Loaded', { is_upcoming_beta: isUpcomingBeta ?? false })
        track('Persona Sign Viewed', {
          is_upcoming_beta: isUpcomingBeta ?? false,
        })
      }
    }

    const trackSettingsPageViewed = () => {
      trackPage('Page Loaded')
    }

    const trackHomePageViewed = () => {
      trackPage('Page Loaded')
    }

    const trackBioPageViewed = () => {
      if (loaded) {
        trackPage('Page Loaded')
      }
    }

    const trackPersonaEmailCaptured = ({
      instructorSlug,
      location,
      cta,
      action,
      additionalAnalytics,
      level,
      page,
      isUpcomingBeta,
      email,
    }: PersonaEmailCapturedProps) => {
      if (loaded) {
        const analytics = filterUndefinedProps({
          instructor_slug: instructorSlug,
          location,
          cta,
          action,
          level,
          page,
          is_upcoming_beta: isUpcomingBeta ?? false,
          email,
        })
        const extraAnalytics = additionalAnalytics
          ? filterUndefinedProps(additionalAnalytics)
          : {}

        track('Persona Email Captured', {
          ...humps.decamelizeKeys(extraAnalytics),
          ...analytics,
        })
      }
    }

    const trackPersonaSignClick = ({
      instructorSlug,
      location,
      cta,
      action,
      additionalAnalytics,
      level,
      page,
      isUpcomingBeta,
    }: PersonaSignClickProps) => {
      if (loaded) {
        const analytics = filterUndefinedProps({
          instructor_slug: instructorSlug,
          location,
          cta,
          action,
          level,
          page,
          is_upcoming_beta: isUpcomingBeta ?? false,
        })
        const extraAnalytics = additionalAnalytics
          ? filterUndefinedProps(additionalAnalytics)
          : {}

        track('Persona Sign Click', {
          ...humps.decamelizeKeys(extraAnalytics),
          ...analytics,
        })
      }
    }

    // POST PW EVENTS
    const trackPersonaViewed = ({
      location,
      instructorSlug,
      conversationSource,
      suggestedPrompt,
      callId,
      page,
    }: PersonaViewedProps = {}) => {
      if (loaded) {
        track('Persona Viewed', {
          location,
          instructor_slug: instructorSlug,
          conversation_source: conversationSource,
          suggested_prompt: suggestedPrompt,
          conversation_id: callId,
          ...(page && { page }),
        })
      }
    }

    const trackPersonaClick = ({
      instructorSlug,
      location,
      cta,
      action,
      suggestedPrompt,
      callId,
      conversationSource,
      additionalAnalytics,
      level,
      page,
      tileLink,
      currentPage,
      contentPageTab,
      contentCategoryTab,
      contentUuid,
      contentTitle,
      recommendationId,
      rowPositionIndex,
      tilePositionIndex,
      subrowPositionIndex,
      tileTitle,
      tileSubtitle,
    }: PersonaClickProps) => {
      if (loaded) {
        const analytics = filterUndefinedProps({
          instructor_slug: instructorSlug,
          location,
          cta,
          action,
          suggested_prompt: suggestedPrompt,
          conversation_id: callId,
          conversation_source: conversationSource,
          level,
          page,
          tile_link: tileLink,
          tile_title: tileTitle,
          tile_subtitle: tileSubtitle,
          row_position_index: rowPositionIndex,
          tile_position_index: tilePositionIndex,
          subrow_position_index: subrowPositionIndex,
        })
        const extraAnalytics = additionalAnalytics
          ? filterUndefinedProps(additionalAnalytics)
          : {}

        track('Persona Click', {
          ...humps.decamelizeKeys(extraAnalytics),
          ...analytics,
          ...filterCookingCaseProps({
            currentPage,
            contentPageTab,
            contentCategoryTab,
            contentUuid,
            contentTitle,
            recommendationId,
          }),
        })
      }
    }

    const trackPersonaClickWithStrategy = (props: PersonaClickProps) => {
      if (loaded) {
        if (isLoggedIn) {
          trackPersonaClick(props)
        } else {
          trackPersonaSignClick(props)
        }
      }
    }

    const trackCallConnecting = ({
      instructorSlug,
      callId,
      contentUuid,
      contentTitle,
    }: {
      instructorSlug: string
      callId?: string
      contentUuid?: string
      contentTitle?: string
    }) => {
      if (loaded) {
        track('Call Connecting', {
          instructor_slug: instructorSlug,
          conversation_id: callId,
          ...(contentUuid && { content_uuid: contentUuid }),
          ...(contentTitle && { content_title: contentTitle }),
        })
      }
    }

    const trackPersonaConversation = ({
      instructorSlug,
      isUser = false,
      messageId,
      callId,
      suggestedPrompt,
      responseSource,
      contentPageTab,
      contentCategoryTab,
      contentUuid,
      contentTitle,
      recommendationId,
      currentPage,
    }: PersonaConversationProps) => {
      if (loaded) {
        track('Persona Conversation', {
          instructor_slug: instructorSlug,
          response_type: isUser ? 'user' : 'instructor',
          response_source: responseSource,
          message_id: messageId,
          conversation_id: callId,
          suggested_prompt: suggestedPrompt || null,
          ...filterCookingCaseProps({
            contentPageTab,
            contentCategoryTab,
            contentUuid,
            contentTitle,
            recommendationId,
            currentPage,
          }),
        })
      }
    }

    const trackCallForceEnded = ({
      instructorSlug,
      callId,
      conversationSource,
      suggestedPrompt,
      error,
      contentPageTab,
      contentCategoryTab,
      contentUuid,
      contentTitle,
      recommendationId,
      currentPage,
    }: {
      instructorSlug: string
      callId?: string
      conversationSource: string
      suggestedPrompt?: string
      error: string
    } & CookingCaseProps) => {
      if (loaded) {
        track('Call force ended', {
          instructor_slug: instructorSlug,
          conversation_id: callId,
          conversation_source: conversationSource,
          end_call_reason: error,
          suggested_prompt: suggestedPrompt,
          ...filterCookingCaseProps({
            contentPageTab,
            contentCategoryTab,
            contentUuid,
            contentTitle,
            recommendationId,
            currentPage,
          }),
        })
      }
    }

    const trackCallEnded = ({
      instructorSlug,
      callId,
      conversationSource,
      suggestedPrompt,
      contentPageTab,
      contentCategoryTab,
      contentUuid,
      contentTitle,
      recommendationId,
      currentPage,
    }: {
      instructorSlug: string
      callId?: string
      conversationSource: string
      suggestedPrompt?: string
    } & CookingCaseProps) => {
      if (loaded) {
        track('Call ended', {
          instructor_slug: instructorSlug,
          conversation_id: callId,
          conversation_source: conversationSource,
          end_call_reason: 'normal closure',
          suggested_prompt: suggestedPrompt,
          ...filterCookingCaseProps({
            contentPageTab,
            contentCategoryTab,
            contentUuid,
            contentTitle,
            recommendationId,
            currentPage,
          }),
        })
      }
    }

    const trackNegativeFeedbackSubmitted = ({
      instructorSlug,
      issues,
      additionalFeedback,
      callId,
      conversationSource,
      suggestedPrompt,
    }: PersonaNegativeFeedbackProps) => {
      if (loaded) {
        const reasons = issues
          ? Object.fromEntries(
              issues.map((issue, index) => [`reason_${index + 1}`, issue]),
            )
          : {}

        track('Persona Negative Feedback Submitted', {
          instructor_slug: instructorSlug,
          ...reasons,
          response: additionalFeedback,
          conversation_id: callId,
          conversation_source: conversationSource,
          suggested_prompt: suggestedPrompt,
        })
      }
    }

    const trackModalViewed = ({
      modalName,
      pageSource,
    }: {
      modalName: string
      pageSource?: string
    }) => {
      if (loaded) {
        track('Persona Modal Viewed', {
          modal_name: modalName,
          page_source: pageSource,
        })
      }
    }

    const trackModalClicked = ({
      modalName,
      cta,
      pageSource,
    }: {
      modalName: string
      cta: string
      pageSource?: string
    }) => {
      if (loaded) {
        track('Persona Modal Clicked', {
          modal_name: modalName,
          cta,
          page_source: pageSource,
        })
      }
    }

    // SHARED

    const trackPersonaRowViewed = ({
      rowName,
      rowPositionIndex,
      isUpcomingBeta,
    }: PersonaRowViewedProps) => {
      if (loaded) {
        track('Persona Row Viewed', {
          row_name: rowName,
          row_position_index: `${rowPositionIndex}`,
          is_upcoming_beta: isUpcomingBeta ?? false,
        })
      }
    }

    const trackPersonaRowTileViewed = ({
      rowName,
      rowPositionIndex,
      isUpcomingBeta,
      subrowPositionIndex,
      tilePositionIndex,
      tileTitle,
      tileSubtitle,
      tileLink,
      tileInstructorSlug,
    }: PersonaRowTileViewedProps) => {
      if (loaded) {
        track('Persona Row Tile Viewed', {
          row_name: rowName,
          row_position_index: rowPositionIndex
            ? `${rowPositionIndex}`
            : undefined,
          subrow_position_index: subrowPositionIndex
            ? `${subrowPositionIndex}`
            : undefined,
          tile_position_index: tilePositionIndex
            ? `${tilePositionIndex}`
            : undefined,
          tile_title: tileTitle,
          tile_subtitle: tileSubtitle,
          tile_link: tileLink,
          tile_instructor_slug: tileInstructorSlug,
          is_upcoming_beta: isUpcomingBeta ?? false,
        })
      }
    }

    const trackPersonaRowTileClicked = ({
      rowName,
      rowPositionIndex,
      subrowPositionIndex,
      tilePositionIndex,
      tileTitle,
      tileSubtitle,
      tileLink,
      tileInstructorSlug,
      tileClickType = 'thumbnail',
    }: PersonaRowTileViewedProps) => {
      if (loaded) {
        track('Persona Row Tile Clicked', {
          row_name: rowName,
          row_position_index: rowPositionIndex
            ? `${rowPositionIndex}`
            : undefined,
          subrow_position_index: subrowPositionIndex
            ? `${subrowPositionIndex}`
            : undefined,
          tile_position_index: tilePositionIndex
            ? `${tilePositionIndex}`
            : undefined,
          tile_title: tileTitle,
          tile_subtitle: tileSubtitle,
          tile_link: tileLink,
          tile_instructor_slug: tileInstructorSlug,
          tile_click_type: tileClickType,
        })
      }
    }

    const trackPersonaRowScrolled = ({
      rowName,
      rowPositionIndex,
      isUpcomingBeta,
      direction,
      autoScroll = false,
    }: PersonaRowScrolledProps) => {
      if (loaded) {
        track('Persona Row Scrolled', {
          row_name: rowName,
          row_position_index: rowPositionIndex
            ? `${rowPositionIndex}`
            : undefined,
          direction,
          auto_scroll: autoScroll,
          is_upcoming_beta: isUpcomingBeta ?? false,
        })
      }
    }

    return {
      // PRE PW
      trackPersonaLandingViewed,
      trackPersonaSignClick,
      trackPersonaEmailCaptured,
      // POST PW
      trackPersonaViewed,
      trackPersonaClick: trackPersonaClickWithStrategy,
      trackCallConnecting,
      trackPersonaConversation,
      trackCallForceEnded,
      trackCallEnded,
      trackNegativeFeedbackSubmitted,
      trackModalViewed,
      trackModalClicked,
      // SHARED
      trackPersonaRowViewed,
      trackPersonaRowTileViewed,
      trackPersonaRowScrolled,
      trackPersonaRowTileClicked,
      trackSettingsPageViewed,
      trackHomePageViewed,
      trackBioPageViewed,
    }
  }, [isLoggedIn, track, trackPage, loaded])
}
