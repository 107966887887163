import React, { PureComponent } from 'react'

import { Consumer } from '../Dropdown'
import { PROP_TYPE_CHILDREN } from '../constants'
import { renderChildren } from '../children'

export default class DropdownToggle extends PureComponent<{
  children: React.ReactElement
}> {
  static propTypes = {
    children: PROP_TYPE_CHILDREN,
  }

  render() {
    const { children } = this.props

    return (
      <Consumer>
        {({ toggle, setToggleElement, show, ariaControls }) =>
          renderChildren(children, {
            onClick: toggle,
            ref: setToggleElement,
            'aria-controls': ariaControls,
            'aria-haspopup': true,
            'aria-expanded': show,
          })
        }
      </Consumer>
    )
  }
}
