import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Consumer } from '../Dropdown'
import { PROP_TYPE_CHILDREN } from '../constants'

interface DropdownItemProps extends React.HTMLAttributes<HTMLElement> {
  closeOnClick?: boolean
  as?: 'button' | 'a'
  href?: string
  target?: string
}

export default class DropdownItem extends PureComponent<DropdownItemProps> {
  static propTypes = {
    children: PROP_TYPE_CHILDREN,
    className: PropTypes.string,
    closeOnClick: PropTypes.bool,
    onClick: PropTypes.func,
    as: PropTypes.elementType,
  }

  static defaultProps = {
    onClick: () => {},
  }

  handleClick =
    (toggle: (event: React.MouseEvent<HTMLElement>) => void) =>
    (event: React.MouseEvent<HTMLElement>) => {
      if (this.props.closeOnClick) {
        toggle(event)
      }

      this.props.onClick?.(event)
    }

  render() {
    const {
      children,
      className,
      closeOnClick,
      onClick,
      as: Component = 'button',
      ...props
    } = this.props

    const classes = cn({
      'mc-dropdown__item': true,
      [className ?? '']: className,
    })

    return (
      <Consumer>
        {({ toggle }) => (
          <Component
            className={classes}
            onClick={this.handleClick(toggle)}
            {...props}
          >
            {children}
          </Component>
        )}
      </Consumer>
    )
  }
}
