import React, { HTMLAttributes, useEffect, useRef, useState } from 'react'
import { arrayOf, func, object } from 'prop-types'
import { PROP_TYPE_CHILDREN } from '../constants'

interface ClickOutsideProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Fired when an "outside" element is clicked (or externalInsideElements, if provided)
   */
  onClickOutside: (e: Event) => void
  children?: React.ReactNode
  // these want to be refs so that they can update without a re-render
  // they will be populated between render and event firing
  /**
   * Any contents of these elements will be considered "inside", overriding `children`
   * (If you use this prop, you don't need to pass in children)
   */
  externalInsideElements?: { current: HTMLElement | null }[]
}

const ClickOutside = ({
  externalInsideElements,
  onClickOutside,
  children,
  ...rest
}: ClickOutsideProps) => {
  const [isTouch, setIsTouch] = useState(false)

  const box = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const touchOrClick = (e: MouseEvent | TouchEvent) => {
      const elements = externalInsideElements?.map((el) => el.current) ?? [
        box.current,
      ]
      const isInside = elements.some((el) => el?.contains(e.target as Node))

      if (!isInside) {
        onClickOutside(e)
      }
    }

    const onClick = (e: MouseEvent) => {
      if (e.type === 'click' && isTouch) return

      touchOrClick(e)
    }

    const onTouch = (e: TouchEvent) => {
      setIsTouch(true)

      touchOrClick(e)
    }

    document.addEventListener('touchend', onTouch, true)
    document.addEventListener('click', onClick, true)

    return () => {
      document.removeEventListener('touchend', onTouch, true)
      document.removeEventListener('click', onClick, true)
    }
  }, [externalInsideElements, isTouch, onClickOutside])

  if (externalInsideElements) {
    return children
  }

  return (
    <div ref={box} {...rest}>
      {children}
    </div>
  )
}

ClickOutside.propTypes = {
  onClickOutside: func.isRequired,
  children: PROP_TYPE_CHILDREN,
  externalOutsideElements: arrayOf(object),
}

export default ClickOutside
