import React, { useContext } from 'react'

import { DropdownContext } from '../Dropdown'
import DropdownContentControlled from '../DropdownContentControlled'
import { DropdownContentControlledProps } from '../DropdownContentControlled/DropdownContentControlled'

const DropdownContent = (props: DropdownContentControlledProps) => {
  const { show, toggle } = useContext(DropdownContext)

  return <DropdownContentControlled show={show} onClose={toggle} {...props} />
}

export default DropdownContent
